import {Machine, Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  getEconomySystemIntegrationKind,
} from "./get-economy-system-integration-kind";
import {RelevantConfigPart} from "./relevant-config-part";

export interface EconomySystemIntegrationCustomerSettings {
  csv: {
    canExport: (role: Role | null) => boolean;
    canImport: (role: Role | null) => boolean;
  };
  integrationStatus: {
    economySystemIntegrationKind: ActiveEconomySystemIntegrationKind;
    hasActiveEconomySystemIntegration: boolean;
  };
  machines: {
    allowChangeToPuller: boolean;
    allowChangeToSelfPropelled: (machine: Pick<Machine, "canPull">) => boolean;
    canImport: boolean;
  };
  priceItems: {
    addTimeAfterMinutesSetup: (role: Role | null) => boolean;
    allowManualPriceGroupPriceItemOrdering: boolean;
    canImport: boolean;
    enableVolumeDiscount: boolean;
  };
  productGroups: {
    canImport: boolean;
  };
  products: {
    canImport: boolean;
  };
  tasks: {
    sendOnApprove: boolean;
  };
  workTypes: {
    canCreate: boolean;
    canImport: boolean;
  };
}

export function integrationCustomerSettings(
  config: RelevantConfigPart,
): EconomySystemIntegrationCustomerSettings {
  const {
    c5Sync,
    canEditProductGroups,
    canEditProducts,
    createCostLines,
    createSalesLines,
    economicEnableProjectActivitiesImport,
    economicEnableWorkTypeFromProductGroupImport,
    economicSync,
    enablePriceItemVolumeDiscount,
    importExternalWorkTypesFromProductGroups,
    importInternalWorkTypesFromProducts,
    navSync,
    navSyncProfile,
    unicontaMachineDimension,
    unicontaSync,
  } = config;

  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);
  const isImportOrLiveSyncIntegration =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.IMPORT_ONLY ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC;

  // TODO(mr): make `importExternalWorkTypesFromProductGroups` replace `economicEnableWorkTypeFromProductGroupImport`
  const syncWorkTypes =
    (economicSync && economicEnableWorkTypeFromProductGroupImport) ||
    (unicontaSync &&
      (importInternalWorkTypesFromProducts || importExternalWorkTypesFromProductGroups));

  const hasMachineDimension = economicSync || (unicontaSync && unicontaMachineDimension !== null);

  return {
    csv: {
      canExport: (role) => Boolean(role?.consultant),
      canImport: (role) =>
        Boolean(
          role?.consultant &&
            (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
              economicSync),
        ),
    },
    integrationStatus: {
      economySystemIntegrationKind,
      hasActiveEconomySystemIntegration:
        economySystemIntegrationKind !== ActiveEconomySystemIntegrationKind.NONE,
    },

    machines: {
      allowChangeToPuller:
        !unicontaSync &&
        !(c5Sync && navSyncProfile !== "bejstrupm") &&
        !(navSync && (navSyncProfile === "dme" || navSyncProfile === "vredodanmark")),
      allowChangeToSelfPropelled: (machine: Pick<Machine, "canPull">) =>
        !(unicontaSync && machine.canPull) &&
        !(c5Sync && navSyncProfile !== "bejstrupm") &&
        !(navSync && (navSyncProfile === "dme" || navSyncProfile === "vredodanmark")),
      // TODO(mr): canEditMachines was not required for machine import from econ - bug?
      canImport: isImportOrLiveSyncIntegration && hasMachineDimension,
    },
    priceItems: {
      addTimeAfterMinutesSetup: (role) =>
        Boolean(
          role?.consultant &&
            (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
              economicSync),
        ),
      allowManualPriceGroupPriceItemOrdering:
        economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
        isImportOrLiveSyncIntegration ||
        (navSync &&
          (navSyncProfile === "vredodanmark" ||
            navSyncProfile === "hvt" ||
            navSyncProfile === "rosgaard")),
      canImport: isImportOrLiveSyncIntegration && !economicEnableProjectActivitiesImport,
      enableVolumeDiscount: !unicontaSync && enablePriceItemVolumeDiscount,
    },

    productGroups: {
      canImport: isImportOrLiveSyncIntegration && canEditProductGroups,
    },
    products: {
      canImport: isImportOrLiveSyncIntegration && canEditProducts,
    },
    tasks: {
      sendOnApprove: unicontaSync && (createSalesLines || createCostLines),
    },
    workTypes: {
      canCreate: economicSync,
      canImport: isImportOrLiveSyncIntegration && syncWorkTypes,
    },
  };
}
