import {Config} from "@co-common-libs/config";
import {
  Customer,
  Delivery,
  DeliveryLocation,
  Location,
  LocationUrl,
  Order,
  OrderUrl,
  Pickup,
  PickupLocation,
  ReportingSpecification,
  SprayLog,
  Task,
  TaskUrl,
  TransportLog,
  TransportLogUrl,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
  YieldDelivery,
  YieldDeliveryLocation,
  YieldLog,
  YieldLogUrl,
  YieldPickup,
  YieldPickupLocation,
  urlToId,
} from "@co-common-libs/resources";
import {WEEK_DAYS, dateFromString, dateToString, formatDateNumeric} from "@co-common-libs/utils";
import {DateField} from "@co-frontend-libs/components";
import {Check, Query, makeQuery} from "@co-frontend-libs/db-resources";
import {
  AppState,
  QueryQueryStateStruct,
  actions,
  getCustomerSettings,
  getDeliveryArray,
  getDeliveryLocationArray,
  getLocationLookup,
  getOrderArray,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getReportingSpecificationArray,
  getSprayLogArray,
  getTaskArray,
  getTransportLogArray,
  getTransportLogLookup,
  getUnitLookup,
  getWorkTypeLookup,
  getYieldDeliveryArray,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldLogLookup,
  getYieldPickupArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {endOfDay, startOfDay, subDays} from "date-fns";
import _ from "lodash";
import React from "react";
// Allowed for existing code...
// eslint-disable-next-line deprecate/import
import {Cell, Grid} from "react-flexr";
import {FormattedMessage, IntlContext, defineMessages} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {ExpandableLoadingWrapper} from "./expandable-loading-wrapper";
import {GenericLogList} from "./generic-log-list";
import {SprayLogList} from "./spray-log-list";
import {TransportLogList} from "./transport-log-list";
import {YieldLogList} from "./yield-log-list";

const messages = defineMessages({
  fromDate: {defaultMessage: "Fra", id: "customer-instance.label.from-date"},
  period: {
    defaultMessage: "Periode",
    id: "customer-instance.card-title.period",
  },
  sprayForPeriod: {
    defaultMessage: "Sprøjtet i periode",
    id: "customer-instance.card-title.spray-for-period",
  },
  toDate: {defaultMessage: "Til", id: "customer-instance.label.to-date"},
  transportForPeriod: {
    defaultMessage: "Transporteret i periode",
    id: "customer-instance.card-title.transport-for-period",
  },
  yieldForPeriod: {
    defaultMessage: "Udbytte i periode",
    id: "customer-instance.card-title.yield-for-period",
  },
});

export const moveDate = (
  reportDataIsValidFrom: string,
  date?: string | null,
): [false, string | null] | [true, string] => {
  if (date && date < reportDataIsValidFrom) {
    return [true, reportDataIsValidFrom];
  } else {
    return [false, date || null];
  }
};

export const setDate = (
  {
    configPutRequest,
    customerInstanceDateFields,
    customerSettings,
    dateField,
    putQueryKey,
  }: {
    configPutRequest: (data: {key: string; value: any}) => void;
    customerInstanceDateFields: {fromDate: string; toDate: string} | undefined;
    customerSettings: Config;
    dateField: "fromDate" | "toDate";
    putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  },
  date?: string | null,
): boolean => {
  const {reportDataIsValidFrom} = customerSettings;
  const [dateMoved, checkedDate] = moveDate(reportDataIsValidFrom, date);
  const oldValue = customerInstanceDateFields || {};
  const newValue = {...oldValue, [dateField]: checkedDate};
  configPutRequest({
    key: "customerInstanceDateFields",
    value: newValue,
  });
  if (checkedDate) {
    putQueryKey(dateField, checkedDate);
  }
  return dateMoved;
};

interface LogTabStateProps {
  customerSettings: Config;
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderArray: readonly Order[];
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  reportingSpecificationArray: readonly ReportingSpecification[];
  sprayLogArray: readonly SprayLog[];
  taskArray: readonly Task[];
  transportLogArray: readonly TransportLog[];
  transportLogLookup: (url: TransportLogUrl) => TransportLog | undefined;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  yieldDeliveryArray: readonly YieldDelivery[];
  yieldDeliveryLocationArray: readonly YieldDeliveryLocation[];
  yieldLogArray: readonly YieldLog[];
  yieldLogLookup: (url: YieldLogUrl) => YieldLog | undefined;
  yieldPickupArray: readonly YieldPickup[];
  yieldPickupLocationArray: readonly YieldPickupLocation[];
}

interface LogTabDispatchProps {
  configPut: (data: {key: string; value: any}) => void;
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
  temporaryQueriesRequestedForPath: (
    queries: readonly Query[],
    pathName: string,
    key: string,
  ) => void;
}

interface LogTabOwnProps {
  customer: Customer;
  customerInstanceDateFields?: {fromDate: string; toDate: string} | undefined;
  fromDate?: string;
  pathName: string;
  taskSyncedState: ReadonlyMap<string, QueryQueryStateStruct>;
  toDate?: string;
  token: string | null;
}

type LogTabProps = LogTabDispatchProps & LogTabOwnProps & LogTabStateProps;

const TEMPORARY_QUERIES_KEY = "LogTab";

class LogTab extends PureComponent<LogTabProps> {
  state = {
    fromDateMoved: false,
    toDateMoved: false,
  };
  UNSAFE_componentWillMount(): void {
    const {customerSettings, fromDate, toDate} = this.props;
    const {reportDataIsValidFrom} = customerSettings;
    const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
    const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
    this.fetchFromArchive(newFromDate, newToDate);
    this.setState({fromDateMoved, toDateMoved});
  }
  UNSAFE_componentWillReceiveProps(nextProps: LogTabProps): void {
    if (nextProps.fromDate !== this.props.fromDate || nextProps.toDate !== this.props.toDate) {
      const {customerSettings, fromDate, toDate} = nextProps;
      const {reportDataIsValidFrom} = customerSettings;
      const [fromDateMoved, newFromDate] = moveDate(reportDataIsValidFrom, fromDate);
      const [toDateMoved, newToDate] = moveDate(reportDataIsValidFrom, toDate);
      this.fetchFromArchive(newFromDate, newToDate);
      this.setState({fromDateMoved, toDateMoved});
    }
  }
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  buildQueries(fromDate?: string | null, toDate?: string | null): Query[] {
    const {customer} = this.props;
    const customerId = urlToId(customer.url);
    if (fromDate && toDate && toDate >= fromDate) {
      const rangeStartString = startOfDay(dateFromString(fromDate) as Date).toISOString();
      const rangeEndString = endOfDay(dateFromString(toDate) as Date).toISOString();
      const taskCheck: Check = {
        checks: [
          {
            checks: [
              {
                memberName: "workToTimestamp",
                type: "memberGt",
                value: rangeStartString,
              },
              {
                memberName: "workFromTimestamp",
                type: "memberLt",
                value: rangeEndString,
              },
              {memberName: "completed", type: "memberTruthy"},
            ],
            type: "and",
          },
          {
            checks: [
              {
                memberName: "date",
                type: "memberGte",
                value: dateToString(subDays(new Date(rangeStartString), WEEK_DAYS)),
              },
              {
                memberName: "date",
                type: "memberLte",
                value: toDate,
              },
              {memberName: "completed", type: "memberFalsy"},
            ],
            type: "and",
          },
        ],
        type: "or",
      };
      const orderCheck: Check = {
        check: taskCheck,
        fromResource: "task",
        memberName: "order",
        type: "targetOfForeignKey",
      };
      const relatedTaskCheck: Check = {
        check: taskCheck,
        memberName: "task",
        targetType: "task",
        type: "hasForeignKey",
      };
      const relatedTransportLogTaskCheck: Check = {
        check: relatedTaskCheck,
        memberName: "transportlog",
        targetType: "transportLog",
        type: "hasForeignKey",
      };
      const relatedYieldLogTaskCheck: Check = {
        check: relatedTaskCheck,
        memberName: "yieldlog",
        targetType: "yieldLog",
        type: "hasForeignKey",
      };
      const relatedSprayLogTaskCheck: Check = {
        check: relatedTaskCheck,
        memberName: "spraylog",
        targetType: "sprayLog",
        type: "hasForeignKey",
      };
      const queries = [
        makeQuery({
          check: taskCheck,
          filter: {
            fromDate,
            logCustomerID: customerId,
            toDate,
          },
          independentFetch: true,
          resourceName: "task",
        }),
        makeQuery({
          check: orderCheck,
          independentFetch: false,
          resourceName: "order",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "transportLog",
        }),
        makeQuery({
          check: relatedTransportLogTaskCheck,
          independentFetch: false,
          resourceName: "pickupLocation",
        }),
        makeQuery({
          check: relatedTransportLogTaskCheck,
          independentFetch: false,
          resourceName: "deliveryLocation",
        }),
        makeQuery({
          check: {
            check: relatedTransportLogTaskCheck,
            memberName: "location",
            targetType: "pickupLocation",
            type: "hasForeignKey",
          },
          independentFetch: false,
          resourceName: "pickup",
        }),
        makeQuery({
          check: {
            check: relatedTransportLogTaskCheck,
            memberName: "location",
            targetType: "deliveryLocation",
            type: "hasForeignKey",
          },
          independentFetch: false,
          resourceName: "delivery",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "yieldLog",
        }),
        makeQuery({
          check: relatedYieldLogTaskCheck,
          independentFetch: false,
          resourceName: "yieldPickupLocation",
        }),
        makeQuery({
          check: relatedYieldLogTaskCheck,
          independentFetch: false,
          resourceName: "yieldDeliveryLocation",
        }),
        makeQuery({
          check: {
            check: relatedYieldLogTaskCheck,
            memberName: "location",
            targetType: "yieldPickupLocation",
            type: "hasForeignKey",
          },
          independentFetch: false,
          resourceName: "yieldPickup",
        }),
        makeQuery({
          check: {
            check: relatedYieldLogTaskCheck,
            memberName: "location",
            targetType: "yieldDeliveryLocation",
            type: "hasForeignKey",
          },
          independentFetch: false,
          resourceName: "yieldDelivery",
        }),
        makeQuery({
          check: relatedTaskCheck,
          independentFetch: false,
          resourceName: "sprayLog",
        }),
        makeQuery({
          check: relatedSprayLogTaskCheck,
          independentFetch: false,
          resourceName: "sprayLocation",
        }),
        makeQuery({
          check: {
            check: relatedSprayLogTaskCheck,
            memberName: "location",
            targetType: "sprayLocation",
            type: "hasForeignKey",
          },
          independentFetch: false,
          resourceName: "spray",
        }),
      ];
      return queries;
    } else {
      return [];
    }
  }
  fetchFromArchive(fromDate?: string | null, toDate?: string | null): void {
    const queries = this.buildQueries(fromDate, toDate);
    if (queries.length) {
      const {pathName, temporaryQueriesRequestedForPath} = this.props;
      temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY);
    }
  }
  @bind
  handleFromFieldChange(value: string | null): void {
    const {
      configPut: configPutRequest,
      customerInstanceDateFields,
      customerSettings,
      putQueryKey,
    } = this.props;
    this.setState({
      fromDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "fromDate",
          putQueryKey,
        },
        value,
      ),
    });
  }
  @bind
  handleToFieldChange(value: string | null): void {
    const {
      configPut: configPutRequest,
      customerInstanceDateFields,
      customerSettings,
      putQueryKey,
    } = this.props;
    this.setState({
      toDateMoved: setDate(
        {
          configPutRequest,
          customerInstanceDateFields,
          customerSettings,
          dateField: "toDate",
          putQueryKey,
        },
        value,
      ),
    });
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings} = this.props;
    const {
      customer,
      deliveryArray,
      deliveryLocationArray,
      fromDate,
      locationLookup,
      orderArray,
      orderLookup,
      pickupArray,
      pickupLocationArray,
      reportingSpecificationArray,
      sprayLogArray,
      taskSyncedState,
      toDate,
      token,
      transportLogArray,
      transportLogLookup,
      unitLookup,
      workTypeLookup,
      yieldDeliveryArray,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldLogLookup,
      yieldPickupArray,
      yieldPickupLocationArray,
    } = this.props;
    const logs = [];
    let onlineWaiting = false;
    let onlineError = false;
    let validPeriod = false;
    {
      const queries = this.buildQueries(fromDate, toDate);
      if (queries.length) {
        const query = queries[0];
        const querySyncedState = taskSyncedState.get(query.keyString);
        if (!querySyncedState || querySyncedState.queryState.currentlyFullFetching) {
          onlineWaiting = true;
        } else {
          const lastTimestamp = querySyncedState.queryState.fullFetchDataComputedAtTimestamp;
          const {lastErrorTimestamp} = querySyncedState.queryState;
          if (lastErrorTimestamp && lastTimestamp && lastErrorTimestamp > lastTimestamp) {
            onlineError = true;
          }
        }
      }
    }
    const customerUrl = customer.url;
    const customerId = urlToId(customerUrl);
    let taskList: Task[] = [];
    const customerOrderUrlSet = new Set<OrderUrl>();
    if (!onlineWaiting && !onlineError && fromDate && toDate && toDate >= fromDate) {
      validPeriod = true;
      orderArray.forEach((order) => {
        if (order.customer === customerUrl) {
          customerOrderUrlSet.add(order.url);
        }
      });
      taskList = _.sortBy(
        this.props.taskArray.filter((task) => {
          const {date} = task;
          if (!date) {
            return false;
          }
          const orderURL = task.order;
          if (!orderURL) {
            return false;
          }

          const deliveryPickupCustomer =
            task.reportingLocations &&
            Object.values(task.reportingLocations).some((l) => l.customer === customerUrl);
          return (
            (deliveryPickupCustomer || customerOrderUrlSet.has(orderURL)) &&
            fromDate <= date &&
            date <= toDate
          );
        }),
        (task) => task.date,
      );
    }
    const taskURLSet = new Set(taskList.map((t) => t.url));
    const customerName = customer.name;
    if (customerSettings.enableTransportLogStatistics) {
      const customerTransportlogURLSet = new Set<TransportLogUrl>();
      pickupLocationArray.forEach((pickupLocation) => {
        if (pickupLocation.customer === customerUrl) {
          customerTransportlogURLSet.add(pickupLocation.transportlog);
        }
      });
      deliveryLocationArray.forEach((deliveryLocation) => {
        if (deliveryLocation.customer === customerUrl) {
          customerTransportlogURLSet.add(deliveryLocation.transportlog);
        }
      });
      const taskForTransportlogURLSet = new Set<TaskUrl>();
      customerTransportlogURLSet.forEach((url) => {
        const transportLog = transportLogLookup(url);
        if (transportLog) {
          taskForTransportlogURLSet.add(transportLog.task);
        }
      });
      const transportTaskList =
        fromDate && toDate
          ? _.sortBy(
              this.props.taskArray.filter((task) => {
                const {date} = task;
                if (!date) {
                  return false;
                }
                const orderURL = task.order;
                if (!orderURL) {
                  return false;
                }
                return (
                  (customerOrderUrlSet.has(orderURL) || taskForTransportlogURLSet.has(task.url)) &&
                  fromDate <= date &&
                  date <= toDate
                );
              }),
              (task) => task.date,
            )
          : [];
      const transportTaskURLSet = new Set(transportTaskList.map((task) => task.url));
      const hasTransportLogs = transportLogArray.some((transportLog) =>
        transportTaskURLSet.has(transportLog.task),
      );
      if (hasTransportLogs) {
        logs.push(
          <ExpandableLoadingWrapper
            key="transport"
            noTasks={!transportTaskList.length}
            onlineError={onlineError}
            onlineWaiting={onlineWaiting}
            title={formatMessage(messages.transportForPeriod)}
            validPeriod={validPeriod}
          >
            <TransportLogList
              customer={customer}
              customerName={customerName}
              customerSettings={this.props.customerSettings}
              deliveryArray={deliveryArray}
              deliveryLocationArray={deliveryLocationArray}
              fromDate={fromDate}
              locationLookup={locationLookup}
              orderLookup={orderLookup}
              pickupArray={pickupArray}
              pickupLocationArray={pickupLocationArray}
              taskList={transportTaskList}
              toDate={toDate}
              token={token}
              transportLogArray={transportLogArray}
              unitLookup={unitLookup}
              workTypeLookup={workTypeLookup}
            />
          </ExpandableLoadingWrapper>,
        );
      }
    }
    if (customerSettings.autoAddYieldLogFor.length) {
      const hasYieldLogs = yieldLogArray.some((yieldLog) => taskURLSet.has(yieldLog.task));
      if (hasYieldLogs) {
        logs.push(
          <ExpandableLoadingWrapper
            key="yield"
            noTasks={!taskList.length}
            onlineError={onlineError}
            onlineWaiting={onlineWaiting}
            title={formatMessage(messages.yieldForPeriod)}
            validPeriod={validPeriod}
          >
            <YieldLogList
              customerName={customerName}
              customerSettings={this.props.customerSettings}
              fromDate={fromDate}
              locationLookup={locationLookup}
              taskList={taskList}
              toDate={toDate}
              token={token}
              workTypeLookup={workTypeLookup}
              yieldDeliveryArray={yieldDeliveryArray}
              yieldDeliveryLocationArray={yieldDeliveryLocationArray}
              yieldLogArray={yieldLogArray}
              yieldLogLookup={yieldLogLookup}
              yieldPickupArray={yieldPickupArray}
              yieldPickupLocationArray={yieldPickupLocationArray}
            />
          </ExpandableLoadingWrapper>,
        );
      }
    }
    if (customerSettings.autoAddSprayLogFor.length) {
      const hasSprayLogs = sprayLogArray.some((sprayLog) => taskURLSet.has(sprayLog.task));
      if (fromDate && toDate && hasSprayLogs) {
        logs.push(
          <ExpandableLoadingWrapper
            key="spray"
            noTasks={!taskList.length}
            onlineError={onlineError}
            onlineWaiting={onlineWaiting}
            title={formatMessage(messages.sprayForPeriod)}
            validPeriod={validPeriod}
          >
            <SprayLogList
              customerName={customerName}
              fromDate={fromDate}
              taskList={taskList}
              toDate={toDate}
            />
          </ExpandableLoadingWrapper>,
        );
      }
    }
    if (fromDate && toDate && customerSettings.showGenericPeriodLogView) {
      const potentialTasksWithLogs = taskList.filter((task) => !task.logSkipped);
      const usedReportSpecifications = new Set(
        potentialTasksWithLogs.map((task) => task.reportingSpecification).filter(Boolean),
      );
      _.sortBy(
        reportingSpecificationArray.filter((reportingSpecification) =>
          usedReportSpecifications.has(reportingSpecification.url),
        ),
        (r) => r.name,
      ).forEach((reportingSpecification) => {
        logs.push(
          <ExpandableLoadingWrapper
            key={reportingSpecification.url}
            noTasks={!taskList.length}
            onlineError={onlineError}
            onlineWaiting={onlineWaiting}
            title={reportingSpecification.name}
            validPeriod={validPeriod}
          >
            <GenericLogList
              customerID={customerId as string}
              customerName={customerName}
              fromDate={fromDate}
              reportingSpecification={reportingSpecification}
              taskList={potentialTasksWithLogs}
              toDate={toDate}
            />
          </ExpandableLoadingWrapper>,
        );
      });
    }
    let noLogs;
    if (validPeriod && !logs.length) {
      noLogs = (
        <div style={{padding: 8, textAlign: "center"}}>
          <FormattedMessage
            defaultMessage="Kunden har ingen logs i den valgte periode."
            id="customer-instance.label.no-logs"
          />
        </div>
      );
    }

    return (
      <div style={{padding: "1em"}}>
        <Card>
          <CardHeader title={formatMessage(messages.period)} />
          <CardContent>
            <Grid>
              <Cell palm="12/12">
                <DateField
                  autoFocus
                  autoOk
                  fullWidth
                  label={formatMessage(messages.fromDate)}
                  margin="dense"
                  value={this.props.fromDate || null}
                  onChange={this.handleFromFieldChange}
                />
              </Cell>
              <Cell palm="12/12">
                <DateField
                  autoOk
                  fullWidth
                  label={formatMessage(messages.toDate)}
                  margin="dense"
                  value={this.props.toDate || null}
                  onChange={this.handleToFieldChange}
                />
              </Cell>
            </Grid>
            <div style={{color: "red"}}>
              {this.state.fromDateMoved ? (
                <div>
                  <FormattedMessage
                    defaultMessage="Den valgte fra dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                    id="customer-instance.label.from-date-error"
                    values={{date: formatDateNumeric(this.props.fromDate)}}
                  />
                </div>
              ) : null}
              {this.state.toDateMoved ? (
                <div>
                  <FormattedMessage
                    defaultMessage="Den valgte til dato lå før den første valide start dato: {date}, så datoen er flyttet frem"
                    id="customer-instance.label.to-date-error"
                    values={{date: formatDateNumeric(this.props.fromDate)}}
                  />
                </div>
              ) : null}
            </div>
          </CardContent>
          {noLogs}
        </Card>
        {logs}
      </div>
    );
  }
}

const ConnectedLogTab: React.ComponentType<LogTabOwnProps> = connect<
  LogTabStateProps,
  LogTabDispatchProps,
  LogTabOwnProps,
  AppState
>(
  createStructuredSelector<AppState, LogTabStateProps>({
    customerSettings: getCustomerSettings,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    locationLookup: getLocationLookup,
    orderArray: getOrderArray,
    orderLookup: getOrderLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
    reportingSpecificationArray: getReportingSpecificationArray,
    sprayLogArray: getSprayLogArray,
    taskArray: getTaskArray,
    transportLogArray: getTransportLogArray,
    transportLogLookup: getTransportLogLookup,
    unitLookup: getUnitLookup,
    workTypeLookup: getWorkTypeLookup,
    yieldDeliveryArray: getYieldDeliveryArray,
    yieldDeliveryLocationArray: getYieldDeliveryLocationArray,
    yieldLogArray: getYieldLogArray,
    yieldLogLookup: getYieldLogLookup,
    yieldPickupArray: getYieldPickupArray,
    yieldPickupLocationArray: getYieldPickupLocationArray,
  }),
  {
    configPut: actions.configPut,
    putQueryKey: actions.putQueryKey,
    temporaryQueriesRequestedForPath: actions.temporaryQueriesRequestedForPath,
  },
)(LogTab);

export {ConnectedLogTab as LogTab};
